<template>
  <div class="ChangePassword">
    <notification-popup
      :dialog="notifSuccess"
      :contentMsg="$t('completedProcess')"
      :headerMsg="$t('succesfully')"
      :nameBtnNo="$t('labelNo')"
      :nameBtnYes="$t('labelOk')"
      imgSource="notification/success2.svg"
      typeModal="transaction"
      disableBtnNo="true"
      :onHandlerNo="buttonNoSuccess"
      :onHandlerYes="buttonOke"
    />

    <notification-popup
      :dialog="notifFailed"
      :contentMsg="$t('FailedSubmit')"
      :headerMsg="$t('failed')"
      :nameBtnNo="$t('labelNo')"
      :nameBtnYes="$t('TryAgain')"
      imgSource="notification/failed.svg"
      disableBtnNo="true"
      typeModal="transaction"
      :onHandlerNo="cancelFailed"
      :onHandlerYes="buttonOkeFailed"
    />

    <notification-popup
      :dialog="dialogRemoveAccount"
      :contentMsg="$t('textDeleteAccount')"
      :headerMsg="$t('DeleteAccount')"
      colorheaderMsg="error--text"
      nameBtnNo="No"
      nameBtnYes="Yes"
      colorIcon="danger"
      :onHandlerNo="clickDeleteNoAccount"
      :onHandlerYes="clickDeleteAccount"
    />

    <div class="item-add">
      <v-container class="pt-0">
        <!-- <v-row justify="center" class="background-card">
          <v-col cols="4" class="pt-0 pb-0">
            <v-img v-if="gender_ === 'm'"
              class="around mt--5"
              :src="require('@/assets/photo-profile.png')"
            ></v-img>
            <v-img v-if="gender_ === 'f'"
              class="around"
              :src="require('@/assets/female-profile.png')">
            </v-img>
          </v-col>
        </v-row> -->
        <v-row justify="center">
          <v-col class="text-center pt-0">
            <b>
              <span class="h4-black">
                {{ full_name }}
              </span>
            </b>
            <br />
            <p class="mb-0 text">{{ currentEmail }}</p>
          </v-col>
        </v-row>
        <v-form v-model="valid">
          <v-row>
            <v-col cols="12" class="pb-0 pt-0">
              <label class="label-content font-weight-bold">{{
                $t("username")
              }}</label>
              <v-text-field
                class="rounded-md"
                v-model="user_name"
                persistent-hint
                type="text"
                :rules="[rules.required]"
                :placeholder="$t('username')"
                required
                filled
                outlined
                disabled
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" class="pb-0 pt-0">
              <label class="label-content font-weight-bold">{{
                $t("email")
              }}</label>
              <v-text-field
                v-model="email"
                type="email"
                :placeholder="$t('email')"
                :rules="[rules.required, rules.emailFormat]"
                required
                outlined
                dense
              >
                <!-- :error="isDangerEmail()"
                :success="isSuccessEmail()"> -->
              </v-text-field>
            </v-col>
            <v-col cols="6" class="pb-0 pt-0">
              <label class="label-content font-weight-bold">{{
                $t("first_name")
              }}</label>
              <v-text-field
                v-model="first_name"
                :placeholder="$t('first_name')"
                :rules="[rules.required]"
                type="text"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="6" class="pb-0 pt-0">
              <label class="label-content font-weight-bold">{{
                $t("last_name")
              }}</label>
              <v-text-field
                v-model="last_name"
                :placeholder="$t('last_name')"
                :rules="[rules.required]"
                type="text"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- 
            <label class="label-content">{{$t('phoneNumber')}}</label> -->
          <v-row>
            <v-col cols="12 pb-0 pb-0 pt-0">
              <label class="label-content font-weight-bold">{{
                $t("MobileNumber")
              }}</label>
            </v-col>
            <v-col cols="4" class="pb-0 pt-0 pr-0">
              <v-autocomplete
                background-color="primary"
                class="autocomplete-cid"
                v-model="phone_cid"
                type="text"
                :items="countries"
                item-text="defaultPlaceholder"
                item-value="dialCode"
                placeholder="Select National"
                :rules="[rules.required]"
                outlined
                dense
                return-object
              >
                <template slot="item" slot-scope="data">
                  <p class="font-weight-black font-weight-normal">
                    {{ data.item.name }}
                  </p>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="8" class="pb-0 pt-0">
              <v-text-field
                v-model="phone_number"
                :prefix="phone_cid ? phone_cid.dialCode : ''"
                :placeholder="$t('phoneNumber')"
                :rules="[rules.requiredPhone, rules.minPhone, rules.maxPhone]"
                type="number"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- <vue-tel-input class="tel-input" v-on:country-changed="countryChanged"
            v-model="phone_number"></vue-tel-input> -->
          <v-row>
            <v-col class="pb-0 pt-0">
              <label class="label-content font-weight-bold">{{
                $t("DateOfBirth")
              }}</label>
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :rules="[rules.required]"
                    :value="computedDateFormattedMomentjs"
                    placeholder="dd-mm-yyyy"
                    readonly
                    required
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="birth_of_date = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  no-title
                  v-model="birth_of_date"
                  @change="menu1 = false"
                  :max="now()"
                  min="1900-01-01"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-vol>
              <label></label>
            </v-vol>
          </v-row>
          <v-row>
            <v-col class="pb-0 pt-0">
              <label class="label-content font-weight-bold">{{
                $t("gender")
              }}</label>
              <v-select
                v-model="gender_"
                v-bind:items="genderItem"
                item-text="name"
                item-value="code"
                dense
                outlined
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pb-0 pt-0">
              <label class="label-content">{{ $t("Address") }}</label>
              <v-textarea
                v-model="address"
                :placeholder="$t('Address')"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pb-0 pt-0">
              <label class="label-content">{{ $t("Occupation") }}</label>
              <v-text-field
                v-model="occupation"
                type="text"
                :placeholder="$t('Occupation')"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- <p @click="notifDeleteAccount()" class="text-capitalize error--text">
            Delete Account
          </p> -->
          <br /><br /><br />
        </v-form>
      </v-container>
    </div>

    <div class="footer">
      <v-row>
        <v-col class="text-center">
          <v-btn
            :disabled="!this.valid"
            rounded
            class="btn-click-change-password"
            color="primary"
            width="80%"
            @click="clickUpdateProfile()"
          >
            {{ $t("Save") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import NotificationPopup from "@/components/NotificationPopup.vue";
import moment from "moment";
import allCountries from "@/components/Countries";

export default {
  name: "EditProfile",
  components: {
    NotificationPopup,
    // VueTelInput,
  },
  data() {
    return {
      dialogRemoveAccount: false,
      notifSuccess: false,
      notifFailed: false,
      showOldPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      address: "",
      occupation: "",
      full_name: "",
      first_name: "",
      last_name: "",
      email: "",
      currentEmail: "",
      phone_number: "",
      user_name: "",
      phone: "",
      birth_of_date: "",
      gender_: "",
      error_: "",
      genderItem: [
        {
          code: "f",
          name: this.$t("Female"),
        },
        {
          code: "m",
          name: this.$t("Male"),
        },
      ],
      valid: false,
      rules: {
        required: (value) => !/^\s*$/.test(value) || this.$t("Required"),
        min: (v) => v.length >= 6 || this.$t("MinSixCharacters"),
        emailMatch: () => this.$t("TheEmailPasswordEnteredDontMatch"),
        emailFormat: (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || this.$t("EmailIsNotInTheCorrectFormat"),
        requiredPhone: (value) =>
          /^[0-9]+$/.test(value) || this.$t("RequiredSpesificNumber"),
        minPhone: (value) => value.length >= 7 || this.$t("MinSevenCharacters"),
        maxPhone: (value) =>
          value.length <= 15 || this.$t("MaxFifteenCharacters"),
        matchPhoneNumber: () => /^(([0-9]))$/,
        // matchPhoneNumber: () => /^((1[3578][0-9])+\d{8})$/,
      },
      phone_cid: "",
      countries: allCountries,
      dateFormatted: null,
      menu1: false,
      // country: null,
      // bindProps: {
      //   mode: "international",
      //   defaultCountry: "VI",
      //   disabledFetchingCountry: false,
      //   disabled: false,
      //   disabledFormatting: false,
      //   placeholder: "Enter a phone number",
      //   required: false,
      //   enabledCountryCode: false,
      //   enabledFlags: true,
      //   preferredCountries: [],
      //   onlyCountries: [],
      //   ignoredCountries: [],
      //   autocomplete: "off",
      //   name: "telephone",
      //   maxLen: 25,
      //   wrapperClasses: "",
      //   inputClasses: "",
      //   dropdownOptions: {
      //     disabledDialCode: false
      //   },
      //   inputOptions: {
      //     showDialCode: false
      //   }
      // },
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.birth_of_date
        ? moment(this.birth_of_date).format("DD-MM-YYYY")
        : "";
    },
    validateForm() {
      return (
        this.valid &&
        this.first_name != "" &&
        this.last_name != "" &&
        this.user_name != "" &&
        this.email != "" &&
        this.password != "" &&
        this.re_type_password != "" &&
        this.birth_of_date != "" &&
        // this.occupation != "" &&
        // this.address != "" &&
        this.phone_number != "" &&
        this.gender_ != ""
      );
    },
  },
  mounted() {
    this.$store
      .dispatch("passenger/GetDataById")
      .then((response) => {
        // console.log('respon ', response)
        this.user_name = response.data.user_name;
        this.full_name =
          response.data.psg_first_name + " " + response.data.psg_last_name;
        this.first_name = response.data.psg_first_name;
        this.last_name = response.data.psg_last_name;
        this.gender_ = response.data.psg_gender;
        this.address = response.data.address;
        this.occupation = response.data.occupation;

        for (let dataCountry of allCountries) {
          if (dataCountry.dialCode == response.data.psg_phone_cid) {
            this.phone_cid = dataCountry;
          }
        }
        if(moment(response.data.psg_birthday).isAfter('1900-01-01')) {
          this.birth_of_date = moment(response.data.psg_birthday).format("YYYY-MM-DD");
        } else {
          this.birth_of_date = "";
        }
        this.phone_number = response.data.psg_phone_number;
        this.email = response.data.user_email;
        this.currentEmail = response.data.user_email;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    now() {
      return moment().format('YYYY-MM-DD')
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    acceptNumber(item) {
      var x = item.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      item = !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
      console.log("accept number", item);
    },
    isNumber() {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // re.test(this.email) ? console.log('mdi-check') : console.log('mdi')
      // console.log("as", re.test(this.email))
      let data = "";
      if (this.email.length > 0 && re.test(this.email) == true) {
        data = "mdi-check";
      } else if (this.email.length > 0 && re.test(this.email) == false) {
        data = "mdi-alert-circle-outline";
      }
      return data;
    },
    isEmailValid() {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // re.test(this.email) ? console.log('mdi-check') : console.log('mdi')
      // console.log("as", re.test(this.email))
      let data = "";
      if (this.email.length > 0 && re.test(this.email) == true) {
        data = "mdi-check";
      } else if (this.email.length > 0 && re.test(this.email) == false) {
        data = "mdi-alert-circle-outline";
      }
      return data;
    },

    isSuccessEmail() {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // re.test(this.email) ? console.log('mdi-check') : console.log('mdi')
      let data = false;
      if (this.email.length > 0 && re.test(this.email) == true) {
        data = false;
      } else if (this.email.length > 0 && re.test(this.email) == false) {
        data = true;
      }

      return data;
    },
    isDangerEmail() {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // re.test(this.email) ? console.log('mdi-check') : console.log('mdi')
      let data = false;
      if (this.email.length > 0 && re.test(this.email) == true) {
        data = false;
      } else if (this.email.length > 0 && re.test(this.email) == false) {
        data = true;
      }

      return data;
    },
    clickUpdateProfile() {
      if (this.validateForm) {
        let caller_id;
        if (this.phone_cid.dialCode == undefined) {
          caller_id = this.phone_cid;
        } else {
          caller_id = this.phone_cid.dialCode;
        }

        const payload = {
          psg_first_name: this.first_name,
          psg_last_name: this.last_name,
          user_email: this.email,
          psg_phone_cid: caller_id,
          psg_phone_number: this.phone_number,
          psg_birthday: moment(this.birth_of_date).format("YYYY-MM-DD"),
          psg_gender: this.gender_,
          address: this.address,
          occupation: this.occupation,
        };

        this.$store
          .dispatch("passenger/editProfilGobus", payload)
          .then((response) => {
            console.log("response ", response);
            if (response.status == true) {
              this.notifSuccess = true;
            } else {
              this.notifFailed = true;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    buttonOke() {
      this.notifSuccess = false;
      this.$router.push({ name: "About" });
    },
    buttonOkeFailed() {
      this.notifFailed = false;
    },
    buttonNoSuccess() {
      this.notifSuccess = false;
    },
    cancelFailed() {
      this.notifFailed = false;
    },
    clickDeleteNoAccount() {
      this.dialogRemoveAccount = false;
    },
    notifDeleteAccount() {
      this.dialogRemoveAccount = true;

      // if (this.notifFailed == false) this.$router.push({ name: "PaymentMethodAdd" });
    },
    clickDeleteAccount() {
      this.$router.push({ name: "About" });
    },
  },
};
</script>

<style lang="scss" scoped>
.around {
  border-radius: 50% !important;
}
.border-input {
  border-color: grey !important;
}
.text {
  font-size: 0.85rem;
}
/* If the screen size is 601px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {
  .p-useraname {
    margin-top: -2%;
  }

  .p-email {
    margin-top: -1%;
  }

  .image-profile {
    width: auto;
    height: 100px;
  }
  .item-add {
    background-color: #ffffff;
    margin-top: 2%;
    width: 100%;
  }

  .btn-click-change-password {
    text-transform: capitalize;
    // position: fixed;
    left: 0;
    // bottom: 0;
    // width: 84%;
    // margin: 0 0 1% 8%;
  }

  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 70px;
    text-align: center;
    background: #ffffff;
    mix-blend-mode: normal;
    /* Field */

    // box-shadow: 0px 1px 10px 0px;
  }

  .tel-input {
    height: 48px;
  }

  .autocomplete-cid {
    text-emphasis-color: #ffffff;
    // background: #4bb14e;
  }
}

/* If the screen max size is 600px, set the font-size of <div> to 80px */
@media only screen and (max-width: 600px) {
  .p-useraname {
    margin-top: -10%;
  }

  .p-email {
    margin-top: -5%;
  }

  .image-profile {
    width: auto;
    height: 60px;
  }

  .item-add {
    width: 100%;
    margin-top: 2%;
    background-color: #ffffff;
  }

  .btn-click-change-password {
    text-transform: capitalize;
    // position: fixed;
    left: 0;
    // bottom: 0;
    // width: 90%;
    // margin: 0 0 5% 5%;
  }

  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 70px;
    text-align: center;
    background: #ffffff;
    mix-blend-mode: normal;
    /* Field */

    // box-shadow: 0px 1px 10px 0px;
  }
  .tel-input {
    height: 48px;
  }

  .autocomplete-cid {
    text-emphasis-color: #ffffff;
    // background: #4bb14e;
  }
}
</style>